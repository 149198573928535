<template>
      <div class="text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                    <div class="flex flex-wrap items-center content-between justify-between">
                        <span class="font-semibold text-base">Data Pelanggan</span>
                        <div class="flex">
                            <div  class="text-xs relative" > 
                                <div class="absolute top-2.5 left-2.5">
                                    <unicon name="search" class="" width="14.34px" height="14.33px" fill="#A1A1AA" ></unicon>
                                    
                                </div>

                                <input
                                v-model="keyword"

                                type="text"
                                :class="`block border border-gray-200 w-72 pr-2 pl-8 px-4 rounded-lg text-xs`"
                                name="keyword"
                                ref="keyword"
                                id="keyword"
                                @keyup.enter="searchData()"
                                placeholder="Pencarian" />
                            </div>
                        </div>
                    
                    </div>
                    <div class="mt-4">
                        <table class="w-full">
                            <thead>
                                <tr class="border-b border-gray-200 text-gray-400 font-semibold">
                                    <td class="py-4">Nama</td>
                                    <td>Email</td>
                                    <td>Nomor Telepon</td>
                                    <td >Mulai Kontrak</td>
                                    <td >Batas Kontrak</td>
                                    <td >Aksi</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.id" class="border-b border-gray-200 font-semibold hover:bg-gray-100">
                                    <td class="py-4">
                                        <div class="flex items-center">
                                           <div class="w-8 h-8 rounded-full mr-2">
                                                <img :src="item.foto ? default_url + item.foto : default_url + 'default.png'" alt="" class="w-8 h-8 rounded-full object-cover">
                                            </div>
                                            <div>
                                                <p>{{item.name}}</p>
                                                <p class="font-semibold">{{item.company_name}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span>{{item.email}}</span>
                                    </td>
                                    <td>
                                        <span>{{item.phone}}</span>
                                    </td>
                                    <td>
                                        <span>{{parseDate(item.start_date,'YYYY-MM-DD')}}</span>
                                    </td>
                                       <td>
                                        <span>{{parseDate(item.expired_date,'YYYY-MM-DD')}}</span>
                                    </td>
                                    <td>
                                          <button class="text-white mr-1 h-8 w-8 text-xs inline bg-green-500 rounded" @click="$router.push('reseller/edit/'+ item.id)">
                                            <i class="far fa-edit"></i>
                                        </button>
                                        <button class="text-white mr-1 h-8 w-8 text-xs inline bg-blue-500 rounded" @click="$router.push('reseller/detail/'+ item.id)">
                                            <i class="far fa-eye"></i>
                                        </button>
                                        <button class="text-white h-8 w-8 text-xs bg-red-500 rounded" @click="openDelete(item.id)">
                                            <i class="far fa-times" ></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="flex justify-end items-center mt-4">

                            <div class="flex items-center ">
                                <div class="font-medium">
                                <span>{{startNumber}}-{{endNumber}} of {{totalRows}}</span> 
                                </div>
                                <div class="flex">
                                    <unicon name="angle-left" class="cursor-pointer" :fill="currentPage > 1 ? '#00000' : '#A1A1AA'"  @click="previousPage()"></unicon>
                                    <unicon name="angle-right" class="cursor-pointer" :fill="totalRows == endNumber ? '#A1A1AA' : '#00000'" @click="nextPage()"></unicon>
                                </div>
                            </div>

                        </div>
                    </div>
                    <t-modal

                    :header="`Hapus Data`"
                    class="flex flex-wrap content-center"
                    v-model="modal_dialog"
                    style="z-index:111000 !important"
                    :classes="class_modal"
                    >
                        <p class="text-lg">Yakin Hapus Data ?</p> 
                        <div class="grid grid-cols-2 gap-4 mt-4">
                            <button
                            @click="closeModal"
                            type="submit"
                            :class="`col-span-1 text-center py-2 rounded
                         
                            bg-gray-100
                            
                            disabled:opacity-50
                            focus:outline-none my-1`"


                        >Batal</button>
                        <button
                            @click="deleteData()"
                            type="submit"
                            :class="`col-span-1 text-center py-2 rounded
                            text-white
                             merah
                            disabled:opacity-50
                            focus:outline-none my-1`"


                        >Hapus</button>
                        </div>
                        
                    </t-modal>
            </div>
        </div>
    </div>
      
  
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import globalMixin from '../mixin/global'
export default {
    data: () => ({
          data: [],
        totalRows:100,
        perPage:10,
        limit:8,
        startNumber:1,
        endNumber:8,
        currentPage:1,
        disabled:false,
        modal_dialog:false,
        keyword:'',
        foto:'',
        delete_id:'',
        modal_edit:false,
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        getData(){
            this.axios.get('v1/customer?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}&reseller_id=${this.$route.params.id}`,this.config)
            .then((ress) => {
                this.data = ress.data.rows
                this.totalRows = ress.data.count
                if (this.totalRows <= this.limit) {
                    this.endNumber = this.totalRows
                } else if(this.currentPage > 1 ) {
                    let page = this.currentPage - 1
                    this.startNumber = page * this.limit + 1
                    this.endNumber = this.currentPage * this.limit
                    if (this.totalRows < this.endNumber) {
                    this.startNumber = 1

                        this.endNumber = this.totalRows
                    }

                } else {
                    this.endNumber = this.limit
                }
                this.startNumber = this.totalRows < 1 ? 0 : page * this.limit + 1

            })
        },
        openDelete(id) {
            this.delete_id = id
            this.closeModal()
        },
       
        deleteData(){
            this.axios.delete('v1/customer/' + this.delete_id,this.config)
            .then((ress) => {
                 this.closeModal()
                 this.getData()
                  this.$snack.success({
                    text: ress.data.message,

                })
            })
        },
         closeModal() {
            
            this.modal_dialog = !this.modal_dialog
        },
      
    
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    created() {
        this.getData()
    },
}
</script>
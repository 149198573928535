<template>
    <div class="p-8 text-xs">
        <div class="rounded-2xl bg-white p-8">
            <div class="">
                <span class="font-semibold text-base">Detail Reseller</span>
            </div>
            <div class="mt-4 grid grid-cols-12 gap-4">
                <div class="col-span-3">
                    <div class="flex justify-center border-r-2 border-gray-200 p-4">
                        <div class="w-40 h-40 ">
                            <img :src="addData.foto ? addData.foto : default_url + 'default.png'" class="w-40 h-40 object-cover " />

                        </div>
                    </div>
                </div>
                <div class="col-span-9 text-gray-500 ">
                    <div>
                        <div class="text-sm font-bold">Informasi</div>
                        <div class="mt-2 grid grid-cols-3 gap-2">
                            <p>Nama : {{addData.name}}</p>
                            <p>Email : <a :href="`mailto:${addData.email}`" class="text-blue-500">{{addData.email}}</a> </p>
                            <p>Nomor Telepon : <a :href="`tel://${addData.phone}}`" class="text-blue-500">{{addData.phone}}</a></p>
                            <!-- <p>Nama Perusahaan : {{addData.company_name}}</p> -->
                            <p class="col-span-2">Alamat : {{addData.address}}</p>
                            <!-- <p>Website : {{addData.website}}</p> -->
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="text-sm font-bold">Perusahaan</div>
                        <div class="mt-2 grid grid-cols-3 gap-2">
                            <p>Nama Perusahaan : {{addData.company_name}}</p>
                            <p>Website : <a :href="addData.website" class="text-blue-500" target="_blank">{{addData.website}}</a></p>
                            
                        </div>
                    </div>

                    <div class="mt-4">
                        <div class="text-sm font-bold">Lama Kontrak</div>
                        <div class="mt-2 grid grid-cols-3 gap-2">
                            <p>Tanggal Mulai: {{addData.start_date}}</p>
                            <p>Tanggal Berakhir : {{addData.expired_date}}</p>
                            
                        </div>
                    </div>
                    
                    
                    <div class="float-right">
                
                    </div>
                </div>
            </div>                    
        </div>

        <div class="mt-4">
            <contract-componenrt :data="addData.Contracts" v-on:getData="getData" :type="'reseller'" :detail="addData"/>
        </div>
         <div class="mt-4">
            <customer-componenrt/>
        </div>
    </div>
</template>
<script>
import {mapGetters,mapAction, mapActions} from 'vuex'
import { ValidationProvider,ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import contractComponenrt from '../../components/contract.vue'
import customerComponenrt from '../../components/customer.vue'
import moment from 'moment'
export default {
    data: () => ({
        foto:'',
        roles:[],
        province:[],
        city:[],
        district:[],
        addData:{
            email:'',
            name:'',
            company_name:'',
            phone:'',
            expired_date:'',
            foto:'',
            website:'',
            prov_id:'',
            city_id:'',
            dis_id:'',
            address:'',
            start_date:moment().format('YYYY-MM-DD').toString(),
            note:'',
        },
        default_url:process.env.VUE_APP_IMAGES
    }),
    mixins:[globalMixin],
    methods:{
        
        changeProv() {
            this.district = []
            this.addData.city_id = ''
            this.addData.dis_id = ''
            this.getCity()
        },
        changeCity() {
            this.addData.dis_id = ''
            this.getDis()
        },
        async postData(){
            let data = new FormData()
            var request_form = this.addData
            await Object.keys(this.addData).forEach(function(key,val) {
                console.log(key)
                if (key != 'foto') {
                    data.append(key,request_form[key])
                    
                }
            });

            if (this.foto) {
                data.append('foto',this.foto)
            } else {
                data.append('foto','')
            }

            this.axios.put('v1/reseller/' + this.$route.params.id,data,this.config)
            .then((ress) => {
                this.$snack.success({
                    text: ress.data.message,

                })
                this.$router.push('/reseller')
            })
            .catch(err => {
                if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                }
            })
           
        },

        getData() {
            this.axios.get('v1/reseller/' + this.$route.params.id,this.config)
             .then((ress) => {
                this.addData = ress.data
                if (this.addData.foto) {
                    this.addData.foto = this.default_url + this.addData.foto
                }
                this.addData.expired_date = moment(this.addData.expired_date).format('YYYY-MM-DD').toString()
                this.addData.start_date = moment(this.addData.start_date).format('YYYY-MM-DD').toString()
          

            })
        },
         eventChange(event){
             this.default_url = ''
           const files = event.target.files
            this.foto = files[0]
            const fileReader = new FileReader()
            fileReader.addEventListener('load',()=>{
                this.addData.foto=fileReader.result
            })
            fileReader.readAsDataURL(this.foto)
       },
    
    },
    computed:{
        ...mapGetters({
            token:'auth/token',
        }),
       
    },
    async created() {
        await this.getData()
        
    },
    components:{
        contractComponenrt,customerComponenrt
    }
}
</script>